export class Workspace {
    public id: string;
    public name: string;
    public description: string;
    public image_id: string;
    public image: string;
    public image_custom: boolean;
    public share: string;
    public members: string[];

    constructor() {

    }

    static create(jsonObject: any): Workspace {
        const o = new Workspace();
        o.id = jsonObject.id ? jsonObject.id.toString() : null;
        o.name = jsonObject.name ? jsonObject.name : null;
        o.description = jsonObject.description ? jsonObject.description : null;
        o.image_id = jsonObject.image_id ? jsonObject.image_id : null;
        o.image = jsonObject.image ? jsonObject.image : null;
        o.image_custom = jsonObject.image_custom ? jsonObject.image_custom : false;
        o.share = jsonObject.share ? jsonObject.share : null;
        o.members = jsonObject.members ? jsonObject.members : [];
        return o;
    }

}

