
export class AnalyticsInfo {
  public users: any;
  public browsers: object[];
  public os: [];
  public devices = [];
  public heatmap = [];
  public visitors = 0;
  public sessions: any;
  public totals: Totals;
  public trackingID: string;
  public usersMin: number;
  public sessionsMin: number;

  public isValid: boolean;

  constructor() {

    this.totals = new Totals();
    this.users = [{}, {}];
    this.sessions = [{}, {}];
    this.devices = [];
    this.heatmap = [];
    this.visitors = 0;
    this.sessions = 0;
    this.trackingID = '';
    this.usersMin = 0;
    this.sessionsMin = 0;
    this.trackingID = ' ';

    this.isValid = false;
  }

  static create(jsonObject: any) {

    if (jsonObject) {

      const o = new AnalyticsInfo();

      o.totals = jsonObject.totals ? Totals.create(jsonObject.totals) : null;

      if (jsonObject.users) {
        o.users = [{
          name: 'Current',
          series: jsonObject['users']['current']
        },
        {
          name: 'Previous',
          series: jsonObject['users']['past']
        }
        ];
      }
      if (jsonObject.sessions) {
        o.sessions = [{
          name: 'Current',
          series: jsonObject['sessions']['current']
        },
        {
          name: 'Previous',
          series: jsonObject['sessions']['past']
        }
        ];
      }
      o.devices = jsonObject.devices ? jsonObject.devices : null;
      o.browsers = jsonObject.browsers ? jsonObject.browsers : null;
      o.os = jsonObject.os ? jsonObject.os : null;
      o.heatmap = jsonObject.heatmap ? jsonObject.heatmap : null;
      o.heatmap.map(m => {
        m.series.map(s => {
          s['value'] = parseInt(s['value'], 10);
        });
      });
      o.visitors = jsonObject.visitors ? jsonObject.visitors : null;
      o.trackingID = jsonObject.google_analytics ? jsonObject.google_analytics : null;
      o.usersMin = jsonObject.users.min ? jsonObject.users.min : null;
      o.sessionsMin = jsonObject.sessions.min ? jsonObject.sessions.min : null;
      o.isValid = true;
      return o;
    } else {
      return new AnalyticsInfo();
    }

  }
}

export class UsersGraphs {
  public current: any;

  constructor() {
    this.current = [];
  }

}


export class Totals {

  public sessions_current: number;
  public sessions_past: number;
  public users_current: number;
  public users_past: number;

  constructor() {
    this.sessions_current = -1;
    this.sessions_past = -1;
    this.users_current = -1;
    this.users_past = -1;

  }

  static create(jsonObject: any) {
    const o = new Totals();

    o.sessions_current = jsonObject['sessions']['current'];
    o.sessions_past = jsonObject['sessions']['past'];
    o.users_current = jsonObject['users']['current'];
    o.users_past = jsonObject['users']['past'];
    return o;
  }
}

