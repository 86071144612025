
export class TeamMember {
    public id: string;
    public avatar?: string;
    public fullname?: string;
    public email?: string;
    public user_id?: string;
    public permissions?: string;

    constructor() { }


     static create(jsonObject: any): TeamMember {

        const o = new TeamMember();
        
        o.id = jsonObject.id ? jsonObject.id : null;
        o.avatar = jsonObject.user.avatar ? jsonObject.user.avatar : null;
        o.fullname = jsonObject.user.fullname ? jsonObject.user.fullname : null;
        o.email = jsonObject.user.email ? jsonObject.user.email : null;
        o.user_id = jsonObject.user.id ? jsonObject.user.id : null;

        o.permissions = jsonObject.permissions[0] ? jsonObject.permissions[0] : null;

        return o;

    }
}
