import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import 'zone.js';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // window.console.log = function () { };

  Sentry.init({
    dsn: "https://b023ef4db33a6981807c897eaff444e6@o283780.ingest.us.sentry.io/4507272524595200",
    integrations: [
      Sentry.browserTracingIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "app.wejewel.ai", "https://wejewel-13884.web.app"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
