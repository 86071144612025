<div class="signContainer">
  <div class="are">
    <div class="left">
      <div class="title">{{'COMMON.RESET_YOUR_PASSWORD' | translate}}</div>

      <form [formGroup]="forgotForm" (ngSubmit)="passwordFormSubmit()" class="form">
        <mat-form-field class="mb-3" style="width: 100%">
          <mat-label>{{'COMMON.PASSWORD' | translate}}</mat-label>
          <input matInput #password required formControlName="password" placeholder="{{'COMMON.PASSWORD' | translate}}">
          <mat-hint>{{'ENTRY.SIGNUP.YOUR_PASSWORD_MUST_BE_MINIMUM' | translate }}</mat-hint>

        </mat-form-field>
        <!--@angular-material-extensions/password-strength's main component-->

        <div class="mb-3 mt-3">
          <div class="strength" style="display: flex; justify-content: center; margin-top: 20px">
            <ul class="strengthBar">
              <li class="point" [style.background-color]="bar0"></li>
              <li class="point" [style.background-color]="bar1"></li>
              <li class="point" [style.background-color]="bar2"></li>
              <li class="point" [style.background-color]="bar3"></li>
            </ul>
            <br>
          </div>
        </div>

        <button mat-raised-button class="whiteTextButton" type="submit" color="primary"
          [disabled]="this.passwordStrongness < 40">{{
          'COMMON.CONTINUE' | translate}}</button>

        <div class="items">
          <a class="link strong" routerLink="/">
            {{ translate.instant('ENTRY.PASSWORD_RESET.BACK_TO_MENU') }}</a>
        </div>
      </form>

    </div>

    <div class="right background-tint">
      <div class="top">
        <!-- <img src="assets/img/logow.png" width="150" height="32" /> -->
      </div>
      <div class="texte">
        Design software for custom-made jewelry
      </div>
      <div class="bottom">
      </div>
    </div>
  </div>
</div>