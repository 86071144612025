import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iishapes-app-subscription-problem',
  templateUrl: './subscription-problem.component.html',
  styleUrls: ['./subscription-problem.component.sass']
})
export class SubscriptionProblemComponent implements OnInit {


  params = {
    link: 'support@2shapes.com'
  };

  constructor() { }

  ngOnInit() {
  }

}
