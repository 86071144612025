import { Injectable } from '@angular/core';
import { FingerSize } from '../models/regions';

@Injectable({
  providedIn: 'root'
})
export class UserExecutionGlobalValuesService {
  public ringSize: FingerSize;
  public finger_region: string;
  public default_metal: string;
  public currency: string;
  public available_metals: string[];
  public visitor_ip: string;
  public visitor_country: string;
  public visitor_location: any;
  public available_countries: string[];
  public apikey: string;
  constructor() {
    this.visitor_country = '';
  }

  canWeSendToVisitor() {

    if (!this.visitor_country) return true;
    return this.available_countries.filter(c => c == this.visitor_country).length > 0;



  }


}
