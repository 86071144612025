import { UserDesignSettings } from '../../../../../../libs/scene-manager/src/lib/models/userDesignSettings';
import { DeveloperApikey } from './developer-user-apikey';
import { Label } from './label';
import { Member } from './member';
import { Organization } from './organization';
import { Partner } from "./partner";
import { Workspace } from './workspace';

export class User {
  public id: number;
  public apikey: string;
  public fullname: string;
  public username: string;
  public email: string;
  public organization: Organization;
  public permissions: string[];
  public developerApikey: DeveloperApikey[];

  public status: number;
  public avatar: string;
  public background: string;
  public jobtitle: string;
  public phone: string;
  public about: string;
  public lang: string;
  public created_at?: Date;
  public updated_at?: Date;
  public customer_id: string;
  public subscription_id: string;
  public ringRegion: string;
  public member!: Member;
  public partner?: Partner;
  public product_cloud_expired_at?: Date;
  public product_cloud_license_type?: string;
  public product_design_expired_at?: Date;
  public product_design_license_type?: string;

  public product_cloud_license_valid: boolean;
  public product_design_license_valid: boolean;

  public beta: boolean;
  public days_left: number;

  public design_settings: UserDesignSettings;

  public workspaces: Workspace[];
  public labels: Label[];
  public dark_mode: boolean;

  constructor() {
    this.permissions = [];
    this.member = new Member();
    this.organization = new Organization();
    this.avatar = '';
    this.product_cloud_license_valid = false;
    this.product_design_license_valid = false;
    this.beta = false;
    this.dark_mode = false;
    this.developerApikey = [];
    this.workspaces = [];
    this.labels = [];
    this.design_settings = new UserDesignSettings();
  }

  static create(jsonObject: any) {

    const o = new User();
    o.id = jsonObject.id ? jsonObject.id : -1;
    o.apikey = jsonObject.apikey ? jsonObject.apikey : null;
    o.fullname = jsonObject.fullname ? jsonObject.fullname : null;
    o.username = jsonObject.username ? jsonObject.username : null;

    o.email = jsonObject.email ? jsonObject.email : null;
    o.dark_mode = jsonObject.dark_mode ? jsonObject.dark_mode : false;
    o.beta = jsonObject.beta ? jsonObject.beta : false;
    o.days_left = jsonObject.days_left ? jsonObject.days_left : null;
    o.organization = jsonObject.organization
      ? Organization.create(jsonObject.organization)
      : null;
    o.partner = jsonObject.partner
      ? Partner.create(jsonObject.partner)
      : null;
    if (jsonObject.permissions) {
      for (const perm of jsonObject.permissions) {
        o.permissions.push(perm);
      }
    }
    if (jsonObject.developer_apikey) {
      for (const devApikey of jsonObject.developer_apikey) {
        o.developerApikey.push(DeveloperApikey.create(devApikey));
      }
    }




    o.member = jsonObject.member ? Member.create(jsonObject.member) : null;

    o.phone = jsonObject.phone ? jsonObject.phone : null;
    o.about = jsonObject.about ? jsonObject.about : null;
    o.beta = jsonObject.beta ? jsonObject.beta : false;
    o.status = jsonObject.status ? jsonObject.status : -1;
    o.avatar = jsonObject.avatar ? jsonObject.avatar : null;
    o.background = jsonObject.background ? jsonObject.background : null;
    o.jobtitle = jsonObject.jobtitle ? jsonObject.jobtitle : null;
    o.customer_id = jsonObject.customer_id ? jsonObject.customer_id : null;
    o.subscription_id = jsonObject.subscription_id
      ? jsonObject.subscription_id
      : null;

    o.lang = jsonObject.lang ? jsonObject.lang : null;

    o.created_at = jsonObject.created_at ? jsonObject.created_at : null;
    o.updated_at = jsonObject.updated_at ? jsonObject.updated_at : null;

    o.product_cloud_expired_at = jsonObject.product_cloud_expired_at ? jsonObject.product_cloud_expired_at : null;
    o.product_cloud_license_type = jsonObject.product_cloud_license_type ? jsonObject.product_cloud_license_type : null;
    o.product_design_expired_at = jsonObject.product_design_expired_at ? jsonObject.product_design_expired_at : null;

    o.product_cloud_license_valid = new Date(o.product_cloud_expired_at).getTime() > Date.now();
    o.product_design_license_valid = new Date(o.product_design_expired_at).getTime() > Date.now();

    o.design_settings = jsonObject.design_settings ? jsonObject.design_settings : new UserDesignSettings();


    if (jsonObject.workspaces) {
      for (const ws of jsonObject.workspaces) {
        const workspace = Workspace.create(ws);
        o.workspaces.push(workspace);
      }
    }

    if (jsonObject.labels) {
      for (const l of jsonObject.labels) {
        const label = Label.create(l);
        o.labels.push(label);
      }
    }


    return o;
  }

  static update(jsonObject: any, user: User) {
    const o = new User();
    o.id = jsonObject.id ? jsonObject.id : user.id;
    o.apikey = jsonObject.apikey ? jsonObject.apikey : user.apikey;
    o.fullname = jsonObject.fullname ? jsonObject.fullname : user.fullname;
    o.username = jsonObject.username ? jsonObject.username : user.username;

    o.email = jsonObject.email ? jsonObject.email : user.email;
    o.organization = jsonObject.organization
      ? Organization.create(jsonObject.organization)
      : user.organization;
    if (jsonObject.permissions) {
      for (const perm of jsonObject.permissions) {
        o.permissions.push(perm);
      }
    } else {
      o.permissions = user.permissions;
    }


    o.member = jsonObject.member ? Member.create(jsonObject.member) : null;
    o.phone = jsonObject.phone ? jsonObject.phone : user.phone;
    o.about = jsonObject.about ? jsonObject.about : user.about;
    o.status = jsonObject.status ? jsonObject.status : user.status;
    o.avatar = jsonObject.avatar ? jsonObject.avatar : user.avatar;
    o.background = jsonObject.background ? jsonObject.background : user.background;
    o.jobtitle = jsonObject.jobtitle ? jsonObject.jobtitle : user.jobtitle;
    o.customer_id = jsonObject.customer_id ? jsonObject.customer_id : user.customer_id;
    o.subscription_id = jsonObject.subscription_id
      ? jsonObject.subscription_id
      : user.subscription_id;

    o.lang = jsonObject.lang ? jsonObject.lang : user.lang;

    o.created_at = jsonObject.created_at ? jsonObject.created_at : user.created_at;
    o.updated_at = jsonObject.updated_at ? jsonObject.updated_at : user.updated_at;

    o.member = jsonObject.member ? Member.create(jsonObject.member) : null;
    o.beta = jsonObject.beta ? jsonObject.beta : false;
    o.dark_mode = jsonObject.dark_mode ? jsonObject.dark_mode : false;

    o.product_cloud_license_valid = new Date(o.product_cloud_expired_at).getTime() > Date.now();
    o.product_design_license_valid = new Date(o.product_design_expired_at).getTime() > Date.now();
    if (jsonObject.developer_apikey) {
      for (const devApikey of jsonObject.developer_apikey) {
        o.developerApikey.push(DeveloperApikey.create(devApikey));
      }
    }

    if (jsonObject.workspaces) {
      for (const ws of jsonObject.workspaces) {
        const workspace = Workspace.create(ws);
        o.workspaces.push(workspace);
      }
    }

    if (jsonObject.labels) {
      for (const l of jsonObject.labels) {
        const label = Label.create(l);
        o.labels.push(label);
      }
    }

    return o;
  }

  isTrial() {
    return this.product_cloud_license_type == 'TRIAL' || this.product_design_license_type == 'TRIAL';
  }
  is2ShapesAdmin() {
    let isn = false;
    this.permissions.map(e => {
      if (e === 'ROLE_ADMIN') {
        isn = true;
      }
    });
    return isn;
  }
  is2ShapesStaff() {
    let isn = false;

    this.permissions.map(e => {
      if (e === 'ROLE_STAFF') {
        isn = true;
      }
    });
    return isn;
  }


  isCloudLicenseValid() {
    return this.product_cloud_license_valid;
  }

  isDesignLicenseValid() {
    return this.product_design_license_valid;
  }


  isOrphan() {


    return (this.member == null);

  }
  isOwner() {


    return this.member?.permissions?.includes('ROLE_OWNER');

  }
  isSupervisor() {
    return this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_SUPERVISOR');

  }
  isUser() {
    return this.member?.permissions?.includes('ROLE_USER');
  }



  hasAccessToDesign() {
    return this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_DESIGN');
  }
  hasAccessToBoutique() {

    const hasPermission = this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_POS');

    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return hasPermission;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return hasPermission;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return hasPermission;
    }

    return false;



  }

  hasAccessToPrintingService() {

    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return false;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;



  }





  hasAccessToOnline() {

    const hasPermission = this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_ONLINE');

    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return hasPermission;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return hasPermission;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return hasPermission;
    }

    return false;



  }

  hasAccessToCRM() {

    const hasPermission = this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_CRM');

    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return hasPermission;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return hasPermission;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;

  }
  hasAccessToInventory() {

    const hasPermission = this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_INVENTORY');

    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return hasPermission;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return hasPermission;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return hasPermission;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;


  }
  hasAccessToWorkshop() {

    const hasPermission = this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_WORKSHOP');

    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return true;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return hasPermission;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;



  }
  hasAccessToDashboard() {
    return this.member?.permissions?.includes('ROLE_OWNER') || this.member?.permissions?.includes('ROLE_DASHBOARD');
  }



  hasAccessToDownload() {
    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return true;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return true;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return true;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return true;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;




  }

  hasAccessToBreakdown() {
    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return true;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return true;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;




  }

  hasAccessToPricing() {
    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return true;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return true;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;




  }

  hasAccessToCloudRendering() {
    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return false;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;




  }

  isStandardOrPremium() {
    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return false;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return true;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;


  }

  isPremium() {
    if (this.organization.plan_id == "FREE") {
      return false;
    }

    if (this.organization.plan_id.includes("BUSINESS")) {
      return false;
    }

    if (this.organization.plan_id.includes("EDU")) {
      return false;
    }

    if (this.organization.plan_id.includes("SCHOOL")) {
      return false;
    }
    if (this.organization.plan_id.includes("STANDARD")) {
      return false;
    }
    if (this.organization.plan_id.includes("PREMIUM")) {
      return true;
    }
    if (this.organization.plan_id.includes("NFR")) {
      return true;
    }

    return false;


  }


}