import { UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { PasswordTools } from '../../../shared/models/password.tools';
import { MessageService } from '../../../shared/services/message.service';
import { UserService } from '../../../shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'iishapes-app-superuser',
  templateUrl: './superuser.component.html',
  styleUrls: ['./superuser.component.sass'],
  providers: [UpperCasePipe, TranslatePipe]
})
export class SuperuserComponent implements OnInit {
  FormLoginSuperuser: UntypedFormGroup;

  showSpinner: boolean;


  constructor(private router: Router,
    private userService: UserService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private messageService: MessageService,

  ) {
    this.showSpinner = false;
    this.FormLoginSuperuser = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      user_email: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit() {

  }

  login() {
    const email = this.FormLoginSuperuser.get('email').value;
    const password = PasswordTools.SHA1(this.FormLoginSuperuser.get('password').value);
    const user_email = this.FormLoginSuperuser.get('user_email').value;
    this.showSpinner = true;

    this.userService.loginSuperuser(email, password, user_email).subscribe(
      () => {


        if (this.userService.user.organization == null) {
          this.router.navigate(['/welcome/new']).then();
        } else {

          if (this.userService.user.hasAccessToDesign()) {
            this.router.navigate(['/design']);
            return;
          }

          if (this.userService.user.hasAccessToBoutique()) {
            this.router.navigate(['/pos']);
            return;
          }

          if (this.userService.user.hasAccessToCRM()) {
            this.router.navigate(['/crm']);
            return;
          }

          if (this.userService.user.hasAccessToInventory()) {
            this.router.navigate(['/vendor']);
            return;
          }
          if (this.userService.user.hasAccessToWorkshop()) {
            this.router.navigate(['/workshop']);
            return;
          }

          if (this.userService.user.hasAccessToDashboard()) {
            this.router.navigate(['/dashboard']);
            return;
          }
        }

      },
      err => {
        this.showSpinner = false;

        if (err?.message == 'INVALID_SUBSCRIPTION') {
          this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.INVALID_SUBSCRIPTION');
          return;
        }

        switch (err.error.message) {

          case 'USER_INVALID': {
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.INVALID_USER');
            break;
          }

          case 'USER_IS_NOT_ACTIVE': {
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_IS_NOT_ACTIVE');
            break;
          }

          case 'USER_IS_DISABLED': {
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_IS_DISABLED');
            break;
          }
          case 'USER_IS_NOT_SUPERUSER': {
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_IS_NOT_SUPERUSER');
            break;
          }
          case 'USER_DOESNT_EXIST': {
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_DOESNT_EXIST');
            break;
          }

          default: {
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_OR_PASSWORD_WRONG');
            break;
          }

        }
      },
      () => {
      }
    );
  }

}
