import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'iishapes-app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.sass']
})
export class ValidateComponent implements AfterViewInit {


  serverError: string;
  showSpinner: boolean;
  showValidateOK: boolean;
  token = 'mytoken';

  constructor(private route: ActivatedRoute,
    public translate: TranslateService,

    private userService: UserService) {
    this.serverError = '';

    this.showSpinner = true;
    this.showValidateOK = false;

    this.translate.use(this.translate.getBrowserLang());


    this.route.params.forEach((params: Params) => {
      this.token = params['id'];
    }).then();

  }

  ngAfterViewInit(): void {
    this.showSpinner = true;
    this.userService.validate(this.token).subscribe(() => {
      this.showValidateOK = true;
      window.open('https://www.2shapes.com/your-account-has-been-activated', '_blank');
    }, (error) => {

      if (error['error']['message'] === 'PUBLIC.USER_ALREADY_ACTIVATED') {
        this.showValidateOK = true;
        window.open('https://www.2shapes.com/your-account-has-been-activated', '_blank');
      }

      this.serverError = 'ERRORS.' + error['error']['message'];
      this.showSpinner = false;
    });
  }


}
