import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { PasswordTools } from '../../../shared/models/password.tools';
import { ListsService } from '../../../shared/services/lists.service';
import { MessageService } from '../../../shared/services/message.service';
import { UserService } from '../../../shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'iishapes-app-try',
  templateUrl: 'try.component.html',
  styleUrls: ['try.component.sass'],
  providers: [TranslatePipe]
})
export class TryComponent implements OnInit, AfterContentChecked {
  signupForm: UntypedFormGroup;
  showSpinner: boolean;
  showPasswordChecks: boolean;
  public showPassword = false;
  showPasswordHints = false;

  tempPass: string;
  checkLength: boolean;
  checkNumbersAndLetters: boolean;
  checkSpecialChars: boolean;
  countries = [];
  partner = 0;

  source = "Unknown";

  ipAddress = '';

  passwordStrongness = 0;
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;

  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];


  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private listsService: ListsService,
    private messageService: MessageService,
    private ref: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.showSpinner = false;


    this.translate.use(this.translate.getBrowserLang());
    this.getIPAddress();

    this.route.params.forEach((params: Params) => {
      const lang = params['lang'];

      this.partner = params['partner'];

      this.source = params['source'];
      if (this.translate.langs.includes(lang)) {
        this.translate.use(lang);
      }

      if (this.translate.langs.includes(lang)) {
        this.translate.use(lang);
      }


    }).then();



    this.signupForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      phone: ['', [Validators.minLength(7)]],
      company: [''],
      invitation_code: ['', [Validators.required, Validators.pattern('.*WJ.*'), Validators.minLength(5)]]
    });


    this.signupForm.controls['password'].valueChanges.subscribe(res => {
      this.showPasswordHints = true;
      this.tempPass = res;
      this.setBarColors(4, '#DDD');
      if (this.tempPass) {

        const cc = this.checkStrength(this.tempPass);
        const c = this.getColor(cc);
        this.passwordStrongness = cc;
        this.setBarColors(c.index, c.color);
      }


    });

    this.userService.existUserAndIsValidInBackend().subscribe(res => {
      //  this.hideFullLoading = !res;
      if (res == true) {
        if (this.router.url.length > 3) {

          this.router.navigate([
            this.router.url
              .replace(' ', '')
              .replace('/?url=', '')
              .replace(/%2F/g, '/')
          ]);
        } else {
          this.router.navigate(['/home']);
        }
      }

    });

  }
  onStrengthChanged($event) {

    this.passwordStrongness = $event;
    this.showPasswordChecks = ($event < 90 && $event > 10) ? true : false;
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  ngOnInit() {

    this.showPasswordChecks = false;

  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  getIPAddress() {

    try {
      this.http.get<{ ip: string }>('https://api.ipify.org/?format=json')
        .subscribe(
          data => {
            if (data && data.hasOwnProperty('ip')) {
              this.ipAddress = data['ip'];
            }
          },
          () => {

          });
    } catch (error) {
    }
  }

  getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index]
    };
  }

  setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }

  signupFormSubmit() {
    const name = this.signupForm.get('name').value;
    const email = this.signupForm.get('email').value;
    const phone = this.signupForm.get('phone').value;
    const invitationCode = this.signupForm.get('invitation_code').value;

    const password = PasswordTools.SHA1(this.signupForm.get('password').value);


    this.showSpinner = true;


    const langCode = this.listsService.getLanguageCode(this.translate.currentLang);


    this.userService.signup(name, email, password, langCode, this.partner, this.ipAddress, phone, this.source, invitationCode).subscribe(
      () => {
        this.showSpinner = false;
        //   this.step = 4;
        this.router.navigate(['/signup/done'], { queryParams: { email: email } }).then();
      },
      err => {
        this.showSpinner = false;
        switch (err) {
          case 'EXIST_A_USER_WITH_THIS_EMAIL':
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.EMAIL_EXISTS');
            break;

          case '"USER_INVALID"':
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.INVALID_USERS');
            break;

          default:
            this.messageService.showMessage('MESSAGE_CONTROL.LOGIN.USER_OR_PASSWORD_WRONG');
        }
      },
      () => {
      }
    );
  }

  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    return force;
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

}
