import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';



@Injectable()
export class AuthguardService implements CanActivateChild {



	constructor(private userService: UserService, private router: Router) {
	}

	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		if (this.userService.isLoggedIn) {

			if (this.canUserGo(state.url) == false) {

				if (this.userService.user.hasAccessToDesign()) {
					this.router.navigate(['/design']);
					return of(true);
				}


				return of(true);
			}

			return of(true);

		}
		return this.userService.existUserAndIsValidInBackend()
			.pipe(map((res: boolean) => {
				if (!res) {
					this.router.navigate(['/login'], { queryParams: { url: state.url } });
				} else {

					if (this.userService.user.dark_mode) {
						document.documentElement.setAttribute('theme', 'dark');
					}
					// if (this.userService.user.hasAccessToDesign()) {
					// 	this.router.navigate(['/design']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToPOS()) {
					// 	this.router.navigate(['/pos']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToCRM()) {
					// 	this.router.navigate(['/crm']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToInventory()) {
					// 	this.router.navigate(['/inventory']);
					// 	return true;
					// }
					// if (this.userService.user.hasAccessToWorkshop()) {
					// 	this.router.navigate(['/workshop']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToDashboard()) {
					// 	this.router.navigate(['/dashboard']);
					// 	return true;
					// }



					return true;
				}
				return res;
			}));

	}

	canUserGo(url: string): boolean {
		return true;

	}
}