import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './entry/components/login/login.component';
import { PasswordResetDoneComponent } from './entry/components/password-reset-done/password-reset-done.component';
import { PasswordResetComponent } from './entry/components/password-reset/password-reset.component';
import { PasswordSetComponent } from './entry/components/password-set/password-set.component';
import { SignupDoneComponent } from './entry/components/signup-done/signup-done.component';
import { SubscriptionProblemComponent } from './entry/components/subscription-problem/subscription-problem.component';
import { SuperuserComponent } from './entry/components/superuser/superuser.component';
import { TryComponent } from './entry/components/try/try.component';
import { ValidateComponent } from './entry/components/validate/validate.component';
import { IIShapesSharedForbiddenComponent } from './shared/components/forbidden/forbidden.component';
import { IIShapesSharedNotfoundComponent } from './shared/components/notfound/notfound.component';
import { AuthguardService } from './shared/services/authguard.service';



export const ROUTES: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: TryComponent },
  { path: 'signup/done', component: SignupDoneComponent },
  { path: 'signup/:source', component: TryComponent },
  { path: 'try', component: TryComponent },
  { path: 'try/done', component: SignupDoneComponent },
  { path: 'password/reset', component: PasswordResetComponent, pathMatch: 'full' },
  { path: 'password/reset/done', component: PasswordResetDoneComponent, pathMatch: 'full' },
  { path: 'subscription-problem', component: SubscriptionProblemComponent, pathMatch: 'full' },
  { path: 'superuser', component: SuperuserComponent },
  {
    path: 'password/set/:id/:id2',
    component: PasswordSetComponent,
    pathMatch: 'full'
  },
  { path: 'validate/:id', component: ValidateComponent },
  {
    path: 'settings',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivateChild: [AuthguardService],
    data: { preload: true, delay: false }
  },
  {
    path: 'vendor',
    loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule),
    canActivateChild: [AuthguardService],
    data: { preload: true, delay: false }
  },
  {
    path: 'design',
    loadChildren: () => import('./design/design.module').then(m => m.DesignerModule),
    canActivateChild: [AuthguardService],
    data: { preload: true, delay: false }
  },
  {
    path: 'web',
    loadChildren: () => import('./web/web.module').then(m => m.WebModule),
    data: { preload: true, delay: false }
  },
  { path: 'password/reset', component: PasswordResetComponent, pathMatch: 'full' },
  {
    path: 'password/set/:id/:id2',
    component: PasswordSetComponent,
    pathMatch: 'full'
  },
  { path: 'validate/:id', component: ValidateComponent },
  { path: 'forbidden', component: IIShapesSharedForbiddenComponent },
  {
    path: 'customizer',
    loadChildren: () => import('./customizer/customizer.module').then(m => m.CustomizerModule),
    data: { preload: true, delay: false }
  },

  { path: '**', component: IIShapesSharedNotfoundComponent }
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(ROUTES);
