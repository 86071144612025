import { User } from './user.interface';
import { AdjustableSidenavService } from '../../design/services/studio/adjustable-sidenav.service';

export class LocalStorageTools {
	static containLocalUserToken(): boolean {
		const token = localStorage.getItem('wejewel_token');
		if (token == null) {
			return false;
		}
		const des = this.encode(token, 123);
		return (<string>des).split(';').length === 2;

	}

	static saveLocalStorage(user: User) {
		if (typeof user !== 'undefined') {
			const co = user.email + ';' + user.apikey;
			const token = this.encode(co, 123);
			localStorage.setItem('wejewel_token', token);
		}
	}

	static destroyLocalStorage() {
		localStorage.removeItem('wejewel_token');
	}


	// la mateixa funcio es per codificar i decodificar
	static encode(s: string, k: number) {
		let enc = '';
		// make sure that input is string
		const strLength: number = (<string>s).length;

		for (let i = 0; i < strLength; i++) {
			// create block
			const a = s.charCodeAt(i);
			// bitwise XOR
			const b = a ^ k;
			enc = enc + String.fromCharCode(b);
		}
		return enc;
	}

	static containLocalStudioLeftPanelWidth(): number {
		const leftPanelWidth = localStorage.getItem('wejewel_left_panel_width');
		if (leftPanelWidth == null) {
			return;
		}

		return parseInt(leftPanelWidth);
	}

	static saveStudioLeftPanelWidth(adjustableSidenavLeft: AdjustableSidenavService) {
		const leftPanelWidth = (adjustableSidenavLeft.leftSidenavWidth).toString()
		localStorage.setItem('wejewel_left_panel_width', leftPanelWidth);
	}

	static containLocalStudioRightPanelWidth(): number {
		const rightPanelWidth = localStorage.getItem('wejewel_right_panel_width');
		if (rightPanelWidth == null) {
			return;
		}

		return parseInt(rightPanelWidth);
	}

	static saveStudioRightPanelWidth(adjustableSidenavRight: AdjustableSidenavService) {
		const rightPanelWidth = (adjustableSidenavRight.rightSidenavWidth).toString()
		localStorage.setItem('wejewel_right_panel_width', rightPanelWidth);
	}

}
