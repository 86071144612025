import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserDesignSettings } from '../../../../../../libs/scene-manager/src/lib/models/userDesignSettings';
import { environment } from '../../../environments/environment';
import { UpgradeDialogComponent } from '../components/upgrade-dialog/upgrade-dialog.component';
import { LocalStorageTools } from '../models/local.storage';
import { Payment } from '../models/payment.interface';
import { User } from '../models/user.interface';
import { MixpanelService } from './mixpanel.service';


@Injectable({
	providedIn: 'root',
})
export class UserService {

	isLoggedIn = false;

	private _urlLoginDashboard = environment.apiUrl + '/v1/public/login/dashboard';
	private _urlLoginSuperuser = environment.apiUrl + '/v1/public/login/superuser';
	private _urlLoginGoogle = environment.apiUrl + '/v1/public/google';
	private _urlLoginFacebook = environment.apiUrl + '/v1/public/facebook';
	private _urlSignup = environment.apiUrl + '/v1/public/register';
	private _urlTry = environment.apiUrl + '/v1/public/try';

	private _urlValidate = environment.apiUrl + '/v1/public/validate';
	private _urlForgot = environment.apiUrl + '/v1/public/password/reset';
	private _urlNewPassword = environment.apiUrl + '/v1/public/password/set';
	private _urlSession = environment.apiUrl + '/v1/public/session';
	private _urlMeEdit = environment.apiUrl + '/v1/me/edit';
	private _urlAvatarDelete = environment.apiUrl + '/v1/me/avatar';
	private _urlBackgroundProfileDelete = environment.apiUrl + '/v1/me/background';
	private _urlPayment = environment.apiUrl + '/v1/me/payment';
	private _urlFeedback = environment.apiUrl + '/v1/me/feedback';
	private _urlCheckEmail = environment.apiUrl + '/v1/me/check/email';
	private _urlCheckUsername = environment.apiUrl + '/v1/me/check/username';
	private _urlCheckVat = environment.apiUrl + '/v1/me/check/vat';
	private _urlIntegration = environment.apiUrl + '/v1/integration';

	private _urlRenew = environment.apiUrl + '/v1/me/renew';

	private _user: User;
	private _payment: Payment;
	constructor(private http: HttpClient, public translate: TranslateService, public dialog: MatDialog, public router: Router, private mixpanel: MixpanelService) {
		this._user = new User();
	}

	login(email: string, password: string, ip: string): Observable<boolean> {

		// Parameters
		const info: { [id: string]: any; } = {};
		info['email'] = email;
		info['password'] = password;
		info['ip'] = ip;
		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));
		return this.http.post(this._urlLoginDashboard, formData, {})
			.pipe(
				map((res) => {

					this._user = User.create(res);
					LocalStorageTools.saveLocalStorage(this._user);
					this.isLoggedIn = true;

					// Identifiquem a Mixpanel
					this.mixpanel.identify(this._user.email);
					this.mixpanel.trackEvent("LOGIN");
					// Identifiquem a Sentry
					this.mixpanel.setPeople({ "$email": this._user.email })


					//Sentry.setUser({ email: this._user.email });

					this.updateAppLanguage();
					return true;
				}));
	}

	loginSuperuser(email: string, password: string, email_user: string): Observable<boolean> {

		// Parameters
		const info: { [id: string]: any; } = {};
		info['email'] = email;
		info['password'] = password;
		info['email_user'] = email_user;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlLoginSuperuser, formData, {})
			.pipe(
				map((res) => {
					this._user = User.create(res);
					LocalStorageTools.saveLocalStorage(this._user);
					this.isLoggedIn = true;
					this.updateAppLanguage();
					return true;
				}));
	}

	validate(token: string): Observable<boolean> {
		// Parameters
		const info: { [id: string]: any; } = {};
		info['token'] = token;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlValidate, formData, {})
			.pipe(
				map(() => {
					return true;
				}));
	}

	existUserAndIsValidInBackend(): Observable<boolean> {
		if (!LocalStorageTools.containLocalUserToken()) {
			return of(false);
		}

		const token = localStorage.getItem('wejewel_token');
		const des = LocalStorageTools.encode(token, 123);
		const valors: string[] = (<string>des).split(';');
		if (valors.length !== 2) {
			return null;
		}

		const email: string = valors[0];
		const apikey: string = valors[1];

		// Parameters
		const info: { [id: string]: any; } = {};
		info['email'] = email;
		info['apikey'] = apikey;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlSession, formData, {})
			.pipe(
				map((res) => {

					const user = User.create(res);
					this._user = user;
					LocalStorageTools.saveLocalStorage(user);
					this.updateAppLanguage();
					// Mixpanel
					this.mixpanel.identify(user.email);
					this.mixpanel.trackEvent("LOGIN");
					this.mixpanel.setPeople({ "$email": user.email })
					// Identifiquem a Sentry
					//	Sentry.setUser({ email: user.email });
					this.isLoggedIn = true;

					return true;
				})
				,
				catchError(() => {
					LocalStorageTools.destroyLocalStorage();
					return of(false);
				}));
	}

	signup(name: string, email: string, password: string, lang: number = 1033, partner: number, ip: string, phone: string, source: string, invitationCode: string = "No invitation"): Observable<boolean> {

		// Parameters
		const info: { [id: string]: any; } = {};
		info['fullname'] = name;
		info['email'] = email;
		info['phone'] = phone;
		info['password'] = password;
		info['partner'] = partner;
		info['lang'] = lang;
		info['ip'] = ip;
		info['source'] = source;
		info['invitation_code'] = invitationCode;
		info['country'] = "No country";


		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlSignup, formData, {})
			.pipe(
				map(res => {

					this._user = User.create(res);
					LocalStorageTools.saveLocalStorage(this._user);
					this.isLoggedIn = true;
					this.mixpanel.identify(this._user.email);
					this.mixpanel.trackEvent("SIGNUP");
					this.mixpanel.setPeople({ "$email": this._user.email })

					// Identifiquem a Sentry
					//	Sentry.setUser({ email: this._user.email });

					return true;
				}));
	}
	try(name: string, email: string, password: string, lang: number = 1033, partner: number, ip: string): Observable<boolean> {

		// Parameters
		const info: { [id: string]: any; } = {};
		info['fullname'] = name;
		info['email'] = email;
		info['password'] = password;
		info['partner'] = partner;
		info['lang'] = lang;
		info['ip'] = ip;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlTry, formData, {})
			.pipe(
				map(res => {

					this._user = User.create(res);
					LocalStorageTools.saveLocalStorage(this._user);
					this.isLoggedIn = true;
					this.mixpanel.identify(this._user.email);
					return true;
				}));
	}


	forgot(email: string): Observable<boolean> {

		// Parameters
		const info: { [id: string]: any; } = {};
		info['email'] = email;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlForgot, formData, {})
			.pipe(
				map(() => {
					return true;
				}));
	}

	newPassword(email: string, token: string, newPassword: string): Observable<boolean> {
		// Parameters
		const info: { [id: string]: any; } = {};
		info['email'] = email;
		info['token'] = token;
		info['password'] = newPassword;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlNewPassword, formData, {})
			.pipe(
				map(() => {
					return true;
				}));
	}

	isUser(): Observable<boolean> {


		if (this.user.id > -1) {
			return of(true);
		}

	}

	logout() {
		this.isLoggedIn = false;
		this._user = null;
		LocalStorageTools.destroyLocalStorage();

		this.mixpanel.logout();

	}

	updateAppLanguage() {
		switch (this._user.lang) {
			case '1027':
				this.translate.use('ca');
				break;
			case '1034':
				this.translate.use('es');
				break;
			case '1036':
				this.translate.use('fr');
				break;
			case '1040':
				this.translate.use('it');
				break;
			case '1041':
				this.translate.use('ja');
				break;
			case '1042':
				this.translate.use('ko');
				break;
			case '1028':
				this.translate.use('zhcht');
				break;
			case '2052':
				this.translate.use('zhchs');
				break;
			case '2070':
				this.translate.use('pt');
				break;
			case '2073':
				this.translate.use('ru');
				break;
			case '1037':
				this.translate.use('he');
				break;
			case '1066':
				this.translate.use('vi');
				break;
			case '1054':
				this.translate.use('th');
				break;
			case '1025':
				this.translate.use('ar');
				break;
			case '1031':
				this.translate.use('de');
				break;
			case '1033':
				this.translate.use('en');
				break;
			case '1035':
				this.translate.use('fi');
				break;
			case '1038':
				this.translate.use('hu');
				break;
			case '1086':
				this.translate.use('ms');
				break;
			case '1044':
				this.translate.use('nb');
				break;
			case '1053':
				this.translate.use('sv');
				break;
			case '1032':
				this.translate.use('gr');
				break;
			case '1045':
				this.translate.use('pl');
				break;
			case '1029':
				this.translate.use('cs');
				break;
			default:
				this.translate.use('en');
				break;
		}
	}
	updateUser(info: any, user: User): Observable<User> {
		const url = this._urlMeEdit;
		// headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);
		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));
		return this.http.post(url, formData, {
			headers: headers
		}).pipe(
			map(res => {
				this._user = User.update(res, user);
				return this._user;
			}));
	}

	signInWithGoogle(googleToken: string) {
		// Parameters
		const info: { [id: string]: any; } = {};

		info['google_token'] = googleToken;
		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));
		return this.http.post(this._urlLoginGoogle, formData)
			.pipe(
				map(res => {
					this._user = User.create(res);
					LocalStorageTools.saveLocalStorage(this._user);
					this.isLoggedIn = true;
					this.updateAppLanguage();
					return this._user;
				}));
	}

	signInWithFacebook(facebookToken: string) {

		// Parameters
		const info: { [id: string]: any; } = {};
		info['facebook_token'] = facebookToken;
		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlLoginFacebook, formData)
			.pipe(
				map(res => {
					this._user = User.create(res);
					LocalStorageTools.saveLocalStorage(this._user);
					this.isLoggedIn = true;
					this.updateAppLanguage();
					return true;
				}));
	}

	deleteBackgroundProfile(): Observable<User> {
		const url = this._urlBackgroundProfileDelete;
		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);
		return this.http.delete(url, {
			headers: headers
		}).pipe(
			map(res => {
				this._user = User.create(res);
				return this._user;

			}));
	}

	deleteAvatar(): Observable<User> {
		const url = this._urlAvatarDelete;
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);


		return this.http.delete(url, {
			headers: headers
		}).pipe(
			map(res => {
				this._user.avatar = res['avatar'];
				return this._user;
			}));
	}
	checkVatNumber(vatnumber: string): Observable<boolean> {
		if (vatnumber === '' || vatnumber == null || vatnumber.length < 5) {
			return of(false);
		}

		// Parsing
		const countryCodeArray = vatnumber.split('', 2);
		const countryCode = countryCodeArray[0] + countryCodeArray[1];
		const vatnumberArray = vatnumber.split(countryCode);
		vatnumber = vatnumberArray[1];

		const url = this._urlCheckVat + '/' + countryCode + '/' + vatnumber;

		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		return this.http.get(url, {
			headers: headers
		}).pipe(
			map(res => {
				if (res['message'] === 'VAT_VALID') {
					return true;
				}
				return false;

			}));
	}

	checkUsernameAvailable(username: string) {
		const url = this._urlCheckUsername + '/' + username;
		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		return this.http.get(url, {
			headers: headers
		}).pipe(
			map(res => {
				if (res['message'] === 'USERNAME_AVAILABLE') {
					return true;
				}
				return false;

			}));
	}

	checkEmailAvailable(email: string) {
		const url = this._urlCheckEmail + '/' + email;
		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		return this.http.get(url, {
			headers: headers
		}).pipe(
			map(res => {
				if (res['message'] === 'EMAIL_AVAILABLE') {
					return true;
				}
				return false;

			}));
	}
	getPayment(): Observable<Payment> {
		const url = this._urlPayment;

		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		return this.http.get(url, {
			headers: headers
		}).pipe(
			map(res => {
				return <Payment>res;

			}));
	}

	sendFeedback(comment: string, url: string): Observable<boolean> {

		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		// Parameters
		const info: { [id: string]: any; } = {};
		info['comment'] = comment;
		info['url'] = url;

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlFeedback, formData, {
			headers: headers
		})
			.pipe(
				map(() => {
					return true;
				}));
	}

	getMailChimp(): Observable<any> {
		const url = this._urlIntegration + '/mailchimp';
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		return this.http.get(url, {
			headers: headers,
			observe: 'response'
		}).pipe(
			map(response => {
				const res = response.status !== 204 ? response.body : null;
				return res;
			}));
	}

	createMailchimp(apikey?: string, audienceId?: string): Observable<any> {
		const url = this._urlIntegration + '/mailchimp';

		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		// Parameters
		const info: { [id: string]: any; } = {};
		if (apikey) {
			info['apikey'] = apikey;
		}
		if (audienceId) {
			info['list'] = audienceId;
		}


		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(url, formData, {
			headers: headers
		}).pipe(
			map(response => {
				const res = response['message'] && response['message'] === 'INVALID_APIKEY' ? false : response;
				return res;
			}));
	}

	deleteMailchimp(): Observable<any> {
		const url = this._urlIntegration + '/mailchimp';
		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);
		return this.http.delete(url, {
			headers: headers
		}).pipe(
			map(() => {
				return true;
			}));
	}



	get user(): User {
		return this._user;
	}
	set user(value: User) {
		this._user = value;
	}

	get payment(): Payment {
		return this._payment;
	}

	saveDesignSettings(design_settings: UserDesignSettings): Observable<User> {
		const url = this._urlMeEdit;
		// headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);
		const formData: FormData = new FormData();

		const info: { [id: string]: any } = {};
		info['design_settings'] = design_settings;
		formData.append('data', JSON.stringify(info));
		return this.http.post(url, formData, {
			headers: headers
		}).pipe(
			map(() => {
				this._user.design_settings = design_settings;
				return this._user;
			}));
	}

	sendRenew(): Observable<boolean> {

		// Headers
		const headers = new HttpHeaders()
			.append('apikey', this._user.apikey);

		// Parameters
		const info: { [id: string]: any; } = {};

		const formData: FormData = new FormData();
		formData.append('data', JSON.stringify(info));

		return this.http.post(this._urlRenew, formData, {
			headers: headers
		})
			.pipe(
				map(() => {
					return true;
				}));
	}


	showUpgradeDialog(planRequired: string) {
		const dialogRef = this.dialog.open(UpgradeDialogComponent, {
			width: '300px',
			data: {
				message: planRequired
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result === true) {
				this.router.navigate(['dashboard/billing/billing/'],
					{ queryParams: { plan: 'yes' } });

			}
		});

	}

}
