import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileUploadModule } from 'ng2-file-upload';
import { DndModule } from 'ngx-drag-drop';
import { EditorDialogComponent } from '../dashboard/components/common/editor-dialog/editor-dialog.component';
import { AppSwitcherComponent } from './components/app-switcher/app-switcher.component';
import { IIShapesSharedEmptyComponent } from './components/empty/empty.component';
import { IIShapesSharedForbiddenComponent } from './components/forbidden/forbidden.component';
import { FullpageLoadingComponent } from './components/fullpage-loading/fullpage-loading.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MessageEditDialogComponent } from './components/message-edit-dialog/message-edit-dialog.component';
import { IIShapesSharedNotfoundComponent } from './components/notfound/notfound.component';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { IIShapesSharedSearchComponent } from './components/search-component/search-component.component';
import { UpgradeDialogComponent } from './components/upgrade-dialog/upgrade-dialog.component';
import { YesNoDialogComponent } from './components/yesno-dialog/yesno-dialog.component';
import { InjectMaterialDirective } from './directives/inject-material.directive';
import { InjectDirective } from './directives/inject.directive';
import { HexToColorNamePipe } from './pipes/hex-to-color-name.pipe';
import { IisCurrencyPipe } from './pipes/iis-currency.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { IisSimulatorError } from './pipes/simulator-error.pipe';
import { SlugifyPipe } from './pipes/sluggify.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AnalyticsService } from './services/business/analytics.service';
import { DeveloperService } from './services/developer.service';
import { HelpService } from './services/help.service';
import { ListsService } from './services/lists.service';
import { LocalStorageService } from './services/local-storage.service';
import { MessageService } from './services/message.service';
import { MixpanelService } from './services/mixpanel.service';
import { PreviousRouteService } from './services/previous-route.service';
import { FontsService } from './services/profile/fonts.service';
import { ProfileOrganizationService } from './services/profile/profile.organization.service';
import { ProfileTeamService } from './services/profile/profile.team.service';
import { PublicService } from './services/public.service';
import { RegionsService } from './services/regions.service';
import { ToolsService } from './services/tools.service';
import { UserExecutionGlobalValuesService } from './services/user-execution-global-values.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    EditorDialogComponent,
    AppSwitcherComponent,
    ProfileDialogComponent,
    YesNoDialogComponent,
    ProfileFormComponent,
    MessageEditDialogComponent,
    IIShapesSharedNotfoundComponent,
    IIShapesSharedForbiddenComponent,
    IIShapesSharedEmptyComponent, FullpageLoadingComponent,
    IIShapesSharedSearchComponent, MessageDialogComponent, UpgradeDialogComponent,
    SlugifyPipe, JoinPipe, OrderByDatePipe, HexToColorNamePipe, InjectDirective, InjectMaterialDirective, IisSimulatorError, IisCurrencyPipe, TruncatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    ClipboardModule,
    MatSidenavModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatCardModule,
    MatStepperModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatSliderModule,
    MatChipsModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSortModule,
    MatCheckboxModule,
    DragDropModule, MatBadgeModule,
    DndModule,
    FileUploadModule
  ],
  exports: [
    TranslateModule,
    MatTableModule,
    MatListModule,
    MatPaginatorModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatCardModule,
    ClipboardModule,
    MatStepperModule,
    MatMenuModule,
    MatChipsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    DragDropModule,
    MatSortModule,
    MatCheckboxModule,
    TranslateModule,
    TranslatePipe,
    AppSwitcherComponent,
    ProfileDialogComponent,
    YesNoDialogComponent,
    MessageEditDialogComponent, FullpageLoadingComponent,
    IIShapesSharedEmptyComponent, MessageDialogComponent, UpgradeDialogComponent,
    IIShapesSharedSearchComponent, SlugifyPipe, JoinPipe, OrderByDatePipe, HexToColorNamePipe, InjectDirective, InjectMaterialDirective, IisSimulatorError, IisCurrencyPipe, TruncatePipe
  ],
  providers: [
    AnalyticsService,
    ToolsService,
    LocalStorageService,
    ListsService,
    MessageService,
    HelpService,
    RegionsService,
    DeveloperService,
    MixpanelService,
    PublicService,
    UserExecutionGlobalValuesService, PreviousRouteService
  ]
})
export class SharedModule {

  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        AnalyticsService,
        ProfileTeamService,
        ProfileOrganizationService,
        FontsService,
        ListsService,
        MessageService,
      ],
    };
  }
}
