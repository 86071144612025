import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from '../../shared/services/loading.service';
import { MessageService } from '../../shared/services/message.service';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private messageService: MessageService, public router: Router,
        private loadingService: LoadingService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(

            tap(evt => {
                if (evt.type === 0) {
                    this.loadingService.start();
                }

                if (evt instanceof HttpResponse) {
                    this.loadingService.stop();
                }
            }),
            catchError((error: HttpErrorResponse) => {

                this.loadingService.stop();
                switch (error.status) {
                    case 400:
                        const err = <string>error.error['message'];
                        const message = 'ERRORS.' + err;
                        this.messageService.showErrorMessage(message);
                        break;
                    case 403:
                        //this.router.navigate(['forbidden', { replaceUrl: true }]);
                        this.messageService.showErrorMessage('ERRORS.403');
                        break;
                    case 404:
                        this.router.navigate(['notfound'], { replaceUrl: true });
                        break;
                    case 500:
                        this.messageService.showErrorMessage(error.error['message']);
                        console.error('500', error.error['message']);
                    // eslint-disable-next-line no-fallthrough
                    default:
                        break;
                }
                throw error;
            })
        );
    }
}
